<template>
  <div class="mobileNav">
    <div class="mobileNav-logoBox">
      <img src="../assets/images/header/logo.png" alt />
    </div>
    <div class="mobileNav-myAdress">
      {{ isConnect && utils.formatAccount(account) }}
    </div>
    <div class="mobileNav-dialogue" @click="goDialogue">
      <img src="../assets/images/header/dialogue.png" alt />
    </div>
    <div class="mobileNav-showNavBtn">
      <img
        src="../assets/images/header/mobile_nav/open.png"
        alt
        v-if="!isOpen"
        @click="isOpen = true"
      />
      <img
        src="../assets/images/header/mobile_nav/close.png"
        alt
        v-else
        @click="isOpen = false"
      />
    </div>

    <div class="navContainer" v-if="isOpen">
      <div
        class="navItem"
        v-for="(item, index) in navList"
        :key="index"
        @click="setNav(item.action)"
      >
        <div class="nav-item">
          <!-- <a v-if="item.external" :href="item.external" target="_blank" rel="noopener noreferrer">
                <img :src="item.bgImg" />
              </a> -->
          <img
            :src="
              navIndex == item.action && !item.external
                ? item.mobileBgActiveImg
                : item.mobileBgImg
            "
            alt
          />
          <div class="nav-item-textbox">
            <div class="nav-item-icon"></div>
            <div class="nav-item-text">{{ item.title }}</div>
          </div>
        </div>

        <!-- <img :src="navIndex == index ? item.imgActive : item.img" alt /> -->
      </div>
      <!-- <div class="navItem">
        <a
          href="https://mirror.xyz/torizero-redlab.eth"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="../assets/images/header/mobile_nav/economy.png" alt />
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import { navDataList } from '@/common/data'
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters(['web3', 'account', 'isConnect', 'navIndex'])
  },
  data() {
    return {
      navList: navDataList,
      isOpen: false
    }
  },
  methods: {
    ...mapMutations(['savenavIndex']),
    goDialogue: function () {
      window.open(this.utils.dialogueUrl, '_blank')
    },
    setNav(index) {
      this.isOpen = false
      this.savenavIndex(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobileNav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 px2rems(10);
  box-sizing: border-box;
  &-logoBox {
    width: 45%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &-myAdress {
    width: 26%;
    text-align: center;
    color: #fff;
    font-size: px2rems(20);
    font-family: bold;
  }
  &-dialogue {
    width: 6%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &-showNavBtn {
    width: 6%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
.navContainer {
  width: 100%;
  height: calc(100% - 60px);
  padding-top: px2rems(44);
  box-sizing: border-box;
  position: fixed;
  top: px2rem(240);
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.navItem {
  width: 92%;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: px2rems(44);
}
.navItem img {
  width: 100%;
}

.nav-item {
  position: relative;
  font-family: DINCond-MediumAlternate;
  color: #fff;
  &-textbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: px2rems(16);
    display: flex;
    align-items: center;
    width: 48%;
  }
  &-text {
    white-space: nowrap;
    font-size: px2rems(48);
  }
  &-icon {
    width: px2rems(48);
    height: px2rems(48);
    margin-right: px2rems(16);
    // background: red;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
</style>
