// const USE_PRODUCTION_ENV = true
// const prod = 'production'
// const dev = 'development'
// console.log(process.env.NODE_ENV, 'process.env.NODE_ENV-----')
// const baseConfig = {
//   // development
//   [dev]: {
//     RPC_URL: 'https://rinkeby.etherscan.io/',
//     // mainUrl: 'https://servertest.torizero.com/',
//     mainUrl: 'http://54.238.189.58:8080',

//     chainIdConfig: 4, // 4
//     chainName: 'test tori',
//     discordId: '1018415088945860659',
//     discordSecret: 'fugkv251soFh6F3Lpm_Jm9JO5fJW0Z0P'
//   },
//   // production
//   [prod]: {
//     RPC_URL: 'https://mainnet.infura.io/v3/',
//     // mainUrl: 'https://server.torizero.com/',
//     mainUrl: 'http://54.238.189.58:8080',
//     chainIdConfig: 1, // 4
//     chainName: 'prod tori',

//     discordId: '961564144757268550',
//     discordSecret: 'k3GevJ_g6kkomYwj0ks7uaD7WM1aRibY'
//   }
// }

export const config = {
  RPC_URL: 'https://mainnet.infura.io/v3/',
  mainUrl: 'https://server.torizero.com/',
  // mainUrl: 'http://192.168.3.118:8080/',
  chainIdConfig: 1, // 4
  chainName: 'prod tori',

  discordId: '961564144757268550',
  discordSecret: 'k3GevJ_g6kkomYwj0ks7uaD7WM1aRibY'
  // discordId: '1098639560612773950',
  // discordSecret: 'cwpTNudApBqKVxoUY321DctjGq9b1LrO'
}

export const whitePaperURL =
  'https://mirror.xyz/frankbao.eth/N_CBmGji9rb34nEG7UNy29ebo4fn-y9oKh9uu0QTlTI'

// export const projectId = 'ab3a283209dc186e09b7f6bc82112bf9'
// export const projectUrl = 'https://gp4.rungame.net'

// connectWallet projectId
export const projectId = 'e18fdc72c83eadbbb7b709cd2c89b7d1'
export const projectUrl = 'https://marketplace.torizero.com'

export const metadata = {
  name: 'Troizero Market',
  description: 'Troizero Market',
  url: projectUrl, // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
