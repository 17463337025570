import { whitePaperURL } from '@/common/config'
import { NavNo } from '@/common/enum'
import indexjs from '@/common/utils'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    web3: '',
    account: '',
    isConnect: false,
    discordAccount: '',
    navIndex: 0,
    integralVal: 0,
    tipShow: false,
    loadIngShow: false,
    tipTitle: '',
    tipSubtitle: '',
    btnText: '',
    chainIdVal: '',
    isRestart: false,
    tipFun: '',
    globalConfig: {},
    web3Modal: null
  },

  mutations: {
    saveAccount(state, account) {
      state.account = account
    },
    saveIsConnect(state, isConnect) {
      state.isConnect = isConnect
    },
    saveweb3(state, web3) {
      state.web3 = web3
    },
    savenavIndex(state, navIndex) {
      if (navIndex === NavNo.WhitePaper) {
        window.open(whitePaperURL, '_blank')
        return
      }
      if (navIndex != NavNo.Inventory || navIndex != NavNo.MarketPlace) {
        state.loadIngShow = false
      }
      state.navIndex = navIndex
    },
    SET_STATE_MORE(state, data = []) {
      data.forEach((item) => {
        state[item.key] = item.value
      })
    },
    SET_STATE(state, { type, data }) {
      state[type] = data
    }
  },

  actions: {
    getIntegral({ commit, getters }) {
      indexjs.post('getIntegral', { address: getters.account }, (res) => {
        commit('SET_STATE', { type: 'integralVal', data: res.data })
      })
    },
    getGlobalConfig({ commit }) {
      indexjs.post('configItem/list', {}, (data) => {
        const result = indexjs.formatList2Obj(data.data, 'id')
        commit('SET_STATE', {
          type: 'globalConfig',
          data: result
        })
      })
    },
    buyBtnFun({ getters, dispatch }, { amount, propId }) {
      return new Promise((resolve) => {
        indexjs.post(
          'exchangeProp',
          {
            address: getters.account,
            amount,
            propId
          },
          () => {
            dispatch('getIntegral')
            resolve()
          }
        )
      })
    }
  },

  getters: {
    web3: (state) => state.web3,
    account: (state) => state.account,
    discordAccount: (state) => state.discordAccount,
    isConnect: (state) => state.isConnect,
    navIndex: (state) => state.navIndex,
    tipShow: (state) => state.tipShow,
    loadIngShow: (state) => state.loadIngShow,
    tipTitle: (state) => state.tipTitle,
    tipSubtitle: (state) => state.tipSubtitle,
    btnText: (state) => state.btnText,
    chainIdVal: (state) => state.chainIdVal,
    isRestart: (state) => state.isRestart,
    tipFun: (state) => state.tipFun,
    globalConfig: (state) => state.globalConfig,
    integralVal: (state) => state.integralVal,
    web3Modal: (state) => state.web3Modal
  }
})
