<template>
  <div class="tpBox" v-show="tipShow || loadIngShow">
    <div class="tpBox-Container" v-show="tipShow">
      <div class="tpBox-Container-title">{{ tipTitle }}</div>
      <div class="tpBox-Container-text">{{ tipSubtitle }}</div>
      <div class="tpBox-Container-button btnScale" @click="closeBtn()">
        {{ btnText }}
      </div>
    </div>
    <div class="tpBox-loadBox" v-show="loadIngShow">Loading...</div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
// import { config } from '@/common/config'
import { NavNo } from '@/common/enum'
export default {
  name: 'TipsPage',
  computed: {
    ...mapGetters([
      'isRestart',
      'web3',
      'account',
      'isConnect',
      'tipShow',
      'tipTitle',
      'tipSubtitle',
      'btnText',
      'tipFun',
      'loadIngShow',
      'chainIdVal'
    ])
  },
  methods: {
    ...mapMutations(['SET_STATE_MORE', 'savenavIndex']),
    async closeBtn() {
      if (this.isRestart) {
        this.$emit('clearFun')
      }
      if (this.tipFun == 'goBindDiscord') {
        this.savenavIndex(NavNo.AccountLink)
      }

      this.SET_STATE_MORE([
        { key: 'tipShow', value: false },
        { key: 'tipFun', value: '' },
        { key: 'isRestart', value: false }
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
.tpBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  &-Container {
    width: px2rem(455);
    height: px2rem(292);
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../assets/images/tpBg.png');
    @include bgImg;
    @include abCenter;
    &-title {
      width: px2rem(455);
      height: px2rem(53);
      font-size: px2rem(24);
      text-align: center;
      line-height: px2rem(53);
      color: #e45050;
      font-family: Bold;
    }
    &-text {
      width: px2rem(455);
      height: px2rem(174);
      padding: px2rem(24);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-align: center;
      line-height: px2rem(34);
      color: #fff;
      font-size: px2rem(20);
      font-family: Bold;
    }
    &-button {
      width: px2rem(144);
      height: px2rem(35.05);
      text-align: center;
      color: #fff;
      line-height: px2rem(35.05);
      font-size: px2rem(16);
      font-family: Regular;
      cursor: pointer;
      background-image: url('../assets/images/tpBtn.png');
      @include bgImg;
      margin: 0 auto;
      margin-top: px2rem(8);
    }
  }

  &-loadBox {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: px2rem(24);
    font-family: Regular;
  }
}

@media screen and (max-width: 768px) {
  .tpBox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    &-Container {
      width: px2rems(455);
      height: px2rems(292);
      background-image: url('../assets/images/tpBg.png');
      @include bgImg;
      @include abCenter;
      &-title {
        width: px2rems(455);
        height: px2rems(53);
        font-size: px2rems(24);
        text-align: center;
        line-height: px2rems(53);
        color: #e45050;
        font-family: Bold;
      }
      &-text {
        width: px2rems(455);
        height: px2rems(174);
        padding: px2rems(24);
        box-sizing: border-box;
        text-align: center;
        line-height: px2rems(34);
        color: #fff;
        font-size: px2rems(20);
        font-family: Bold;
      }
      &-button {
        width: px2rems(189);
        height: px2rems(46);
        text-align: center;
        color: #fff;
        line-height: px2rems(46);
        font-size: px2rems(16);
        font-family: Regular;
        cursor: pointer;
        background-image: url('../assets/images/tpBtn.png');
        @include bgImg;
        margin: 0 auto;
        margin-top: px2rems(8);
      }
    }
    &-loadBox {
      font-size: px2rems(24);
    }
  }
}
</style>
