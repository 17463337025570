<template>
  <div class="capitalBox">
    <img src="../assets/images/shop/shopImg/capitalBg.png" alt="" />
    <div class="capitalText">{{ integralVal }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['integralVal'])
  }
}
</script>

<style lang="scss" scoped>
.capitalBox {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 13%;
  height: auto;
}
.capitalBox img {
  width: 100%;
}
.capitalText {
  width: 100%;
  text-align: center;
  padding-right: 28%;
  position: absolute;
  top: 52%;
  left: 0;
  transform: translateY(-50%);
  box-sizing: border-box;
  color: #fff;
  font-size: px2rem(16);
  font-family: Light;
}

@media screen and (max-width: 768px) {
  .capitalBox {
    width: 20%;
    top: 23%;
  }
  .capitalText {
    padding-left: px2rems(12);
    box-sizing: border-box;
    color: #fff;
    font-size: px2rems(16);
    font-family: Light;
  }
}
</style>
