<template>
  <div class="goods-card">
    <div class="goods-whitelist" v-if="whiteList">WHITELIST</div>
    <div class="goods-info" v-if="showPrice">
      <div class="goods-price goods-priceicon">
        {{ price }}
      </div>
      <div class="goods-nums">{{ remain }}/{{ total }}</div>
    </div>
    <div class="goods-amount" v-if="showAmount">Total: {{ amount }}</div>
    <img
      class="goods-img"
      :src="'https://marketplace.torizero.com' + icon"
      alt=""
    />
    <div class="goods-name" v-if="name">{{ name }}</div>
    <div class="goods-cashed" v-if="cashed">
      <img src="../assets/images/shop/prizes/cashedImg.png" alt />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPrice: Boolean,
    showAmount: Boolean,
    name: String,
    price: Number,
    icon: String,
    total: Number,
    remain: Number,
    amount: Number,
    whiteList: Boolean,
    cashed: Boolean
  }
}
</script>

<style lang="scss" scoped>
.goods {
  &-card {
    background: url('../assets/images/shop/property/assetBg.png') no-repeat
      center;
    background-size: cover;
    aspect-ratio: 85/112;
    position: relative;
    font-family: Light;
    color: #fff;
  }

  &-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }

  &-name {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: px2rem(14);
    line-height: px2rem(20);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &-nums,
  &-price {
    flex: 1;
    text-align: right;
    line-height: px2rem(20);
    font-size: px2rem(12);
    display: flex;
    align-items: center;
  }
  &-whitelist {
    position: absolute;
    left: 0;
    bottom: 11%;
    width: 100%;
    text-align: center;
    color: #b2b2b2;
    font-size: px2rem(12);
  }
  &-priceicon {
    &::after {
      content: '';
      display: block;
      width: px2rem(16);
      height: px2rem(16);
      background: url('../assets/images/shop/shopImg/priceIcon.png') no-repeat
        center;
      background-size: cover;
      margin-left: px2rem(4);
    }
  }

  &-info,
  &-amount {
    width: 84%;
    height: px2rem(20);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 5%;
    font-size: px2rem(12);
  }

  &-info {
    display: flex;
    justify-content: space-between;
  }

  &-amount {
    text-align: right;
  }

  &-cashed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(50, 50, 50, 0.8);
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .goods {
    &-name {
      font-size: px2rems(24);
      line-height: px2rems(28);
      margin-top: px2rems(24);
    }

    &-nums,
    &-price {
      line-height: px2rems(20);
      font-size: px2rems(18);
    }
    &-whitelist {
      font-size: px2rems(16);
    }
    &-priceicon {
      &::after {
        width: px2rems(18);
        height: px2rems(18);
        margin-left: px2rems(4);
      }
    }

    &-info,
    &-amount {
      height: px2rems(20);
      top: 6%;
      font-size: px2rems(18);
    }
  }
}
</style>
