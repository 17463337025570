<template>
  <div class="prizes-box">
    <div class="BoxHeader">
      <div class="blindBoxTitle">
        <div class="title_text">
          <div>[</div>
          {{ prizesTypes[prizesTypesIndex].title }} {{ total }}
          <div>]</div>
        </div>
      </div>
      <div class="blindBoxDesc">Browse all items you own</div>
      <fragment-num></fragment-num>
      <!-- <div class="capitalBox">
        <img src="../assets/images/shop/shopImg/capitalBg.png" alt />
        <div class="capitalText">{{ integralVal }}</div>
      </div> -->
    </div>
    <div class="prizes-types">
      <div
        class="prizes-types-item btnScale"
        v-for="(item, i) in prizesTypes"
        :key="i"
        @click="chooseType(item, i)"
      >
        <img
          :src="i === prizesTypesIndex ? item.bgActiveImg : item.bgImg"
          alt
        />
        <div class="prizes-types-item-text">{{ item.name }}</div>
      </div>
    </div>
    <div class="prizes-content" v-if="loadIng && total > 0" ref="scrollBox">
      <div class="prizes-content-List" v-if="!isOpenDetail">
        <div
          class="prizes-content-List-Item"
          v-for="(item, index) in prizesShowArr"
          :key="index"
          @click="openDetails(item, index)"
        >
        
          <goods-card
            :icon="item.picture"
            
            :white-list="item.isWhitelist"
            :name="item.name"
            :amount="item.amount"
            show-amount
            :cashed="item.isCashed"
          ></goods-card>
          <!-- <img
            src="../assets/images/shop/property/assetBg.png"
            alt
            class="prizes-content-List-Item-Bg"
          />
          <div class="prizes-content-List-Item-Image">
            <img :src="item.picture" alt />
          </div>
          <div
            class="prizes-content-List-Item-whitelistBox"
            v-if="item.isWhitelist"
          >
            WHITELIST
          </div>
          <div class="prizes-content-List-Item-name" v-if="item.name">
            {{ item.name }}
          </div> -->
          <!-- <div class="prizes-content-List-Item-cashedImg" v-if="item.isCashed">
            <img src="../assets/images/shop/prizes/cashedImg.png" alt />
          </div> -->
        </div>
        <div class="prizes-content-List-Item"></div>
      </div>
      <div class="detailsBox" v-if="isOpenDetail">
        <div class="commodityBox">
          <goods-card
            class="prizesItem"
            style="margin: 0 auto"
            :name="detailsObj.name"
            :white-list="detailsObj.isWhitelist"
            :icon="detailsObj.picture"
          ></goods-card>
          <!-- <div class="prizesItem" style="margin: 0 auto">
            <img
              src="../assets/images/shop/property/assetBg.png"
              alt
              class="prizesItemBg"
            />
            <div class="prizesImage">
              <img :src="detailsObj.picture" alt />
            </div>
            <div class="detailsWhitelist" v-if="detailsObj.isWhitelist">
              WHITELIST
            </div>
          </div> -->
          <!-- <div class="detailsName">{{ detailsObj.name }}</div> -->
          <div class="describe">{{ detailsObj.description }}</div>
          <div
            class="describe"
            v-if="detailsObj.twitter && detailsObj.discord == ''"
          >
            Twitter:
            <a :href="detailsObj.twitter" target="_blank">{{
              detailsObj.twitter
            }}</a>
          </div>
          <div class="describe" v-if="detailsObj.discord">
            Discord:
            <a :href="detailsObj.discord" target="_blank">{{
              detailsObj.discord
            }}</a>
          </div>
          <div class="describe" v-if="detailsObj.mintdate">
            Mint Date : {{ detailsObj.mintdate }}
          </div>
          <div class="btnsBox">
            <div class="detailBtn btnScale" @click="isOpenDetail = false">
              <img src="../assets/images/shop/shopImg/btnBg.png" alt />
              <div class="detailText">CLOSE</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prizes-noThings" v-if="loadIng && total <= 0">
      <div class="prizes-noThings-noThingTip">
        You have 0 items in your inventory.
        <br />Open a
        <span @click="goMystery()"
          >[{{ nowType == 1 ? 'Mystery Box' : 'Marketplace' }}]</span
        >
        to claim rewards.
      </div>
    </div>
    <div class="pagingBox pc" v-if="loadIng && total > 0 && !isOpenDetail">
      <div class="lastPage" @click="lastBtn">
        <img src="../assets/images/shop/property/prevBtn.png" alt />
      </div>
      <!--<div class="pageInput">
        <input
          type="text"
          onkeyup="this.value=this.value.replace(/\D/g, '')"
          v-model="page"
          @keyup.enter="convert_code_to_name"
          @blur="convert_code_to_name"
        />
      </div>-->
      <div class="pagingPages">{{ page }}/{{ pages }}</div>
      <div class="nextPage" @click="nextBtn">
        <img src="../assets/images/shop/property/nextBtn.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import { NavNo } from '@/common/enum'
import { mapGetters, mapMutations } from 'vuex'
import FragmentNum from './FragmentNum.vue'
import GoodsCard from './GoodsCard.vue'
export default {
  name: 'InventoryView',
  components: {
    FragmentNum,
    GoodsCard
  },
  data() {
    return {
      loadIng: false,
      // integralVal: 0,
      prizesTypes: [
        {
          name: 'MYSTERY BOX PRIZES',
          title: 'PRIZES',
          type: NavNo.MysteryBox,
          bgImg: require('../assets/images/shop/prizes/type.png'),
          bgActiveImg: require('../assets/images/shop/prizes/typeActive.png')
        },
        {
          name: 'PURCHASES',
          title: 'PURCHASES',
          type: NavNo.MarketPlace,
          bgImg: require('../assets/images/shop/prizes/type.png'),
          bgActiveImg: require('../assets/images/shop/prizes/typeActive.png')
        }
      ],
      prizesTypesIndex: 0,
      nowType: NavNo.MysteryBox,
      prizesShowArr: [],
      pageSize: 8,
      page: 1,
      total: 0,
      pages: 0,
      scrollBox: null,
      isScroll: false,
      isGet: true,

      isOpenDetail: false,
      detailsObj: {}
    }
  },
  computed: {
    ...mapGetters([
      'web3',
      'account',
      'isConnect',
      'navIndex',
      'globalConfig',
      'integralVal'
    ])
  },
  watch: {
    account() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_STATE', 'savenavIndex']),
    init() {
      this.page = 1
      this.getList()
      this.$store.dispatch('getIntegral')
    },
    scrollFun() {
      this.$nextTick(() => {
        const that = this
        if (!this.$refs.scrollBox) return
        this.$refs.scrollBox.addEventListener('scroll', () => {
          if (this.clientHeight + this.scrollTop >= this.scrollHeight - 50) {
            if (that.pageSize * that.page >= that.total) return
            that.isScroll = true
            if (!that.isGet) return
            that.page += 1
            that.getList()
          }
        })
      })
    },

    openDetails(item) {
      this.detailsObj = item
      this.isOpenDetail = true
    },
    goMystery() {
      this.savenavIndex(this.nowType)
    },

    chooseType(item, index) {
      this.isOpenDetail = false
      this.prizesTypesIndex = index
      this.nowType = item.type
      this.page = 1
      this.prizesShowArr = []
      this.total = 0
      this.getList()
    },
    getList() {
      if (!this.isGet) return
      this.isGet = false
      this.SET_STATE({ type: 'loadIngShow', data: true })
      this.utils.post(
        'getPropList',
        {
          address: this.account,
          type: this.nowType,
          pageNum: this.page,
          pageSize: this.pageSize
        },
        (res) => {
          if (this.navIndex == 0) {
            this.SET_STATE({ type: 'loadIngShow', data: false })
          }
          this.isGet = true
          let resData = res.data
          if (resData) {
            this.total = Number(resData.totalCount)
            this.pageSize = Number(resData.pageSize)
            this.pages = Math.ceil(this.total / this.pageSize)
            this.setList(resData.list)
          }

          this.loadIng = true
          this.scrollFun()
        }
      )
    },
    setList(list) {
      if (!this.isScroll) {
        this.prizesShowArr = []
      }
      for (let i = 0; i < list.length; i++) {
        const tempData = this.globalConfig[list[i].itemId]
        list[i] = {
          ...list[i],
          name: tempData.name,
          description: tempData.description,
          picture: `/static/images/headPortrait/${tempData.id}.png`,
          isWhitelist: tempData.type === 2,
          twitter: tempData.twitter,
          discord: tempData.discord,
          mintdate: tempData.mintdate,
          isCashed: 0,
          endTime: tempData.endtime && this.utils.getCashed(tempData.endtime)
        }
        // list[i]["name"] = this.utils.getntfInfo("name", list[i].itemId);
        // list[i]["description"] = this.utils.getntfInfo(
        //   "description",
        //   list[i].itemId
        // );
        // list[i]["picture"] = this.utils.getntfInfo("pic", list[i].itemId);

        // list[i]["isWhitelist"] = false;
        // if (this.utils.getntfInfo("type", list[i].itemId) == "2") {
        //   list[i]["isWhitelist"] = true;
        // }

        // list[i]["twitter"] = this.utils.getntfInfo("twitter", list[i].itemId);
        // list[i]["discord"] = this.utils.getntfInfo("discord", list[i].itemId);
        // list[i]["isCashed"] = 0;
        // let startime = this.utils.getntfInfo("startime", list[i].itemId);
        // let endtime = this.utils.getntfInfo("endtime", list[i].itemId);
        // let endtime = tempData.endtime;
        // if (endtime) {
        //   list[i].isCashed = this.utils.getCashed(endtime);
        // }
        // list[i]["mintdate"] = this.utils.getntfInfo("mintdate", list[i].itemId);

        this.prizesShowArr.push(list[i])
        if (this.isOpenDetail) {
          if (list[i].id == this.detailsObj.id) {
            this.detailsObj = list[i]
          }
        }
      }
      this.prizesShowArr.sort(function (a, b) {
        return a.isCashed - b.isCashed
      })
    },
    convert_code_to_name() {
      this.getList()
    },
    lastBtn() {
      let page = Number(this.page)
      if (page <= 1) return
      this.isScroll = false
      page -= 1
      this.page = page
      this.getList()
    },
    nextBtn() {
      let page = Number(this.page)
      if (page >= this.pages) return
      this.isScroll = false
      page += 1
      this.page = page
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.loadingBox {
  display: none;
}
.prizes {
  &-box {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
    position: relative;
  }
  &-types {
    width: 100%;
    display: flex;
    margin-top: px2rem(12);
    margin-bottom: px2rem(12);
    &-item {
      width: 30%;
      min-width: 135px;
      position: relative;
      cursor: pointer;
      margin-right: px2rem(18);
      img {
        width: 100%;
      }
      &-text {
        font-size: px2rem(18);
        color: #fff;
        @extend .pos-flex-center;
      }
    }
  }
  &-content {
    width: 100%;
    height: auto;
    position: relative;
    margin-top: 4%;
    &-List {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &:after {
        display: block;
        content: '';
        width: 21%;
        height: 0px;
      }

      &-Item {
        width: 21%;
        height: auto;
        margin-bottom: px2rem(12);
        position: relative;
        &-Bg {
          width: 100%;
        }
      }
    }
  }
  &-noThings {
    width: 100%;
    height: calc(100% - 90px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &-noThingTip {
      width: auto;
      height: auto;
      color: #fff;
      font-size: px2rem(20);
      font-family: Light;
      span {
        cursor: pointer;
        color: red;
      }
    }
  }
}
.pagingBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: px2rem(12);
}
.lastPage,
.nextPage {
  width: px2rem(16);
  cursor: pointer;
}
.lastPage img,
.nextPage img {
  width: 100%;
  height: auto;
}
.pageInput {
  width: px2rem(60);
  padding: 4px 0;
  text-align: center;
  background: rgba(61, 53, 94, 1);
  box-sizing: border-box;
}
.pageInput input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: #8b4931;
  box-sizing: border-box;
  background-color: rgba(46, 35, 69, 1);
  color: rgba(219, 199, 143, 1);
  text-align: center;
}
.pagingPages {
  width: px2rem(60);
  height: auto;
  text-align: center;
  color: #fff;
  font-size: px2rem(18);
}

.detailsBox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: px2rem(18);
}

.prizesItem {
  width: 25%;
  height: auto;
  margin-bottom: px2rem(12);
  position: relative;
}
// .prizesName {
//   width: 100%;
//   text-align: center;
//   font-weight: bold;
//   color: #729ad4;
//   font-size: px2rem(16);
//   line-height: px2rem(20);
//   position: absolute;
//   left: 0;
//   bottom: px2rem(0);
// }
.commodityBox {
  width: 100%;
  height: auto;
}

.describe {
  width: 100%;
  // max-height: px2rem(50);
  margin: 0 auto;
  text-align: center;
  line-height: px2rem(26);
  margin-top: px2rem(12);
  margin-bottom: px2rem(8);
  color: #fff;
  font-size: px2rem(16);
  font-family: Light;
}
.describe a {
  color: #167fe2;
}

.btnsBox {
  @include pcwh(400, 60);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.detailBtn,
.backBtn {
  width: 45%;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}
.detailBtn img,
.backBtn img {
  width: 100%;
}
.detailText {
  width: 100%;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: px2rem(16);
}
@media screen and (max-width: 768px) {
  .loadingBox {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: px2rems(16);
    line-height: px2rems(60);
    height: px2rems(60);
    display: block;
  }
  .prizes {
    &-box {
      width: 100%;
      height: 100%;
      padding: 0;
      box-sizing: border-box;
    }
    &-types {
      width: 94%;
      height: 6%;
      margin: 0 auto;
      display: flex;
      margin-top: px2rems(18);
      margin-bottom: px2rems(18);
      align-items: center;
      justify-content: space-between;
      &-item {
        //width: 48%;
        width: px2rems(274);
        height: px2rems(48.5);
        margin-right: 0;
        img {
          width: 100%;
          height: 100%;
        }
        &-text {
          font-size: px2rems(18);
          color: #fff;
        }
      }
    }
    &-content {
      width: 86%;
      height: 84%;
      margin: 0 auto;
      position: relative;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      &-List {
        &-Item {
          width: 48%;
          margin-bottom: 2%;
          &-whitelistBox {
            width: 100%;
            text-align: center;
            color: #b2b2b2;
            font-size: px2rems(16);
            position: absolute;
            bottom: 10%;
          }
          &-name {
            width: 100%;
            text-align: center;
            font-weight: bold;
            color: #fff;
            font-size: px2rems(14);
            line-height: px2rems(30);
            position: absolute;
            left: 0;
            bottom: px2rems(0);
          }
        }
      }
    }
    &-noThings {
      width: 100%;
      height: 80%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &-noThingTip {
        width: auto;
        height: auto;
        color: #fff;
        font-size: px2rems(20);
        font-family: Light;
        span {
          color: red;
        }
      }
    }
  }

  .pagingBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: px2rems(12);
  }
  .lastPage,
  .nextPage {
    width: px2rems(26);
    cursor: pointer;
  }
  .lastPage img,
  .nextPage img {
    width: 100%;
    height: auto;
  }
  .pageInput {
    width: px2rems(60);
    padding: 4px 0;
    text-align: center;
    background: rgba(61, 53, 94, 1);
    box-sizing: border-box;
  }
  .pageInput input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: #8b4931;
    box-sizing: border-box;
    background-color: rgba(46, 35, 69, 1);
    color: rgba(219, 199, 143, 1);
    text-align: center;
  }
  .pagingPages {
    width: px2rems(60);
    height: auto;
    text-align: center;
    color: #fff;
    font-size: px2rems(18);
  }

  .prizesItem {
    width: 50%;
    margin-bottom: 4%;
  }

  // .prizesName {
  //   height: px2rems(30);
  //   font-size: px2rems(16);
  //   bottom: px2rems(80);
  // }

  // .prizesName {
  //   font-size: px2rems(16);
  //   line-height: px2rems(30);
  //   height: auto;
  //   bottom: px2rems(0);
  // }
  .describe {
    width: 80%;
    height: auto;
    margin: 0 auto;
    line-height: px2rems(40);
    margin-top: px2rems(16);
    margin-bottom: px2rems(12);
    color: #fff;
    font-size: px2rems(16);
    font-family: Light;
  }

  .btnsBox {
    height: auto;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .detailBtn,
  .backBtn {
    width: 40%;
    cursor: pointer;
    margin: 0 auto;
    position: relative;
    margin-bottom: px2rems(16);
  }
  .detailBtn img,
  .backBtn img {
    width: 100%;
  }
  .detailText {
    width: 100%;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #fff;
    font-size: px2rems(16);
  }
}
</style>
