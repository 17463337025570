<template>
  <div class="homePage-container">
    <!--<img src="../assets/images/shop/bg.png" alt class="homePage-container-bg" />-->
    <loginBox
      @loginFun="loginFun"
      @connectWallet="connectWallet"
      v-if="!isLogin"
    ></loginBox>
    <tipBox @clearFun="clearFun" v-if="!isLogin"></tipBox>
    <div class="mobileNavBox mobile" v-if="isLogin">
      <mobileNav></mobileNav>
    </div>
    <div class="homePage-TableBox" v-if="isLogin">
      <div class="homePage-TableBox-Header pc">
        <div class="homePage-TableBox-Header-logo" @click="backHome()">
          <img src="../assets/images/shop/logo.png" alt />
        </div>
        <div class="homePage-TableBox-Header-connectBox">
          <img src="../assets/images/shop/wallet.png" alt />
          <div
            class="homePage-TableBox-Header-connectBox-connectText"
            v-if="!isConnect"
            @click="connectWallet()"
          >
            Administration
          </div>
          <div
            class="homePage-TableBox-Header-connectBox-connectText"
            v-if="isConnect && account"
            @click="connectWallet"
          >
            {{ utils.formatAccount(account) }}
          </div>
        </div>
      </div>

      <div class="homePage-TableBox-table">
        <img src="../assets/images/shop/tableBg.png" alt class="pc" />
        <div class="homePage-TableBox-table-leftNavBox pc">
          <div
            class="homePage-TableBox-table-leftNavBox-navItem btnScale"
            :class="navIndex == index ? 'navItemActive' : ''"
            v-for="(item, index) in navList"
            :key="index"
            @click="savenavIndex(item.action)"
          >
            <div class="nav-item">
              <img
                :src="
                  navIndex == index && !item.external
                    ? item.bgActiveImg
                    : item.bgImg
                "
                alt
              />
              <div class="nav-item-textbox">
                <div class="nav-item-icon"></div>
                <div class="nav-item-text">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="dialogue btnScale" @click="goDialogue">
            <img src="../assets/images/header/dialogue.png" alt />
          </div>
        </div>
        <div class="homePage-TableBox-table-rightContent">
          <tipBox @clearFun="clearFun" v-if="isLogin"></tipBox>
          <inventory-view v-if="navIndex == NavNo.Inventory"></inventory-view>
          <mystery-box v-else-if="navIndex == NavNo.MysteryBox"></mystery-box>
          <market-place
            v-else-if="navIndex == NavNo.MarketPlace"
          ></market-place>
          <account-link
            v-else-if="navIndex == NavNo.AccountLink"
          ></account-link>
          <fragment-view
            v-else-if="navIndex === NavNo.Fragments"
          ></fragment-view>
          <genesis-box v-else-if="navIndex == 6"></genesis-box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Web3 from 'web3'
import { mapGetters, mapMutations } from 'vuex'
import loginBox from '../components/login'
import mobileNav from '../components/mobileNav'
import tipBox from '../components/tips'
import MysteryBox from '../components/mysterybox'
import inventoryView from '../components/inventory'
import MarketPlace from '../components/marketplace'
import accountLink from '../components/accountLink'
import FragmentView from '../components/fragment'
import genesisBox from '../components/genesisBox'
import { navDataList } from '@/common/data'
import { NavNo } from '@/common/enum'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'

import { mainnet } from 'viem/chains'
import { reconnect, watchAccount, disconnect } from '@wagmi/core'
import { projectId, metadata } from '../common/config'

const chains = [mainnet]
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata
})

export default {
  computed: {
    ...mapGetters(['web3', 'account', 'isConnect', 'navIndex', 'chainIdVal'])
  },
  components: {
    mobileNav,
    loginBox,
    inventoryView,
    MysteryBox,
    MarketPlace,
    accountLink,
    genesisBox,
    tipBox,
    FragmentView
  },
  data() {
    return {
      modal: null,
      isLogin: false,
      isEnter: true,
      navList: navDataList,
      NavNo,
      nonce: 'Sign this message to login account link.'
    }
  },
  created() {
    if (this.$route.query.Enter) {
      this.isEnter = false
    }
  },
  mounted() {
    this.modal = createWeb3Modal({
      wagmiConfig: config,
      projectId,
      enableAnalytics: true, // Optional - defaults to your Cloud configuration
      enableOnramp: true // Optional - false as default
    })
    this.SET_STATE({ type: 'web3Modal', data: this.modal })
    reconnect(config)
    watchAccount(config, { onChange: this.accountChange.bind(this) })
  },
  methods: {
    ...mapMutations([
      'savenavIndex',
      'saveAccount',
      'saveIsConnect',
      'saveweb3',
      'SET_STATE',
      'SET_STATE_MORE'
    ]),
    accountChange(account) {
      if (account.address) {
        this.saveAccount(account.address.toLowerCase())
        this.SET_STATE({
          type: 'chainIdVal',
          data: `0x${(account.chainId || 1).toString(16)}`
        })
      } else {
        this.clearFun()
      }
    },
    connectWallet() {
      this.modal.open()
    },
    disconnectWallet() {
      disconnect(config)
    },
    goDialogue: function () {
      window.open(this.utils.dialogueUrl, '_blank')
    },
    clearFun() {
      this.isLogin = false
      this.setAccountConnectStatus('', null, false)
      sessionStorage.setItem('mySession', '')
      this.nowsignature = null
      this.disconnectWallet()
      this.$router.push({ query: {} })
    },
    loginFun() {
      const { code } = this.$route.query
      this.utils.post(
        'signatureAuthentication',
        { address: this.account },
        (res) => {
          sessionStorage.setItem('mySession', res.sessionId)
          this.isLogin = true
          this.SET_STATE({ type: 'isConnect', data: true })

          if (code) {
            this.savenavIndex(NavNo.AccountLink)
          }
        },
        () => {
          this.clearFun()
        }
      )
      this.$router.push({ query: code ? { code } : {} })
    },
    backHome() {
      window.open('https://www.torizero.com/', '_self')
    },
    setAccountConnectStatus(account, web3, connectStatus) {
      this.saveAccount(account)
      this.saveweb3(web3)
      this.saveIsConnect(connectStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  &-container {
    width: 100%;
    //max-width: 1920px;
    @include bgImg;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-image: url('../assets/images/shop/bg.png');

    &-bg {
      width: 100%;
    }
  }
  &-TableBox {
    width: 58%;
    //max-width: 1100px;
    min-width: 785px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px;
    box-sizing: border-box;
    overflow: hidden;
    &-Header {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4%;
      &-logo {
        width: 34%;
        @extend .pointer;
      }
      &-connectBox {
        width: 18%;
        position: relative;
        @extend .pointer;
        img {
          width: 100%;
        }
        &-connectText {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(20);
          font-weight: bold;
        }
      }
    }
    &-table {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      position: relative;
      img {
        width: 100%;
      }
      &-leftNavBox {
        width: calc(24% - 5px);
        height: 100%;
        padding: 12px 0;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        &-navItem {
          width: 90%;
          margin: 0 auto;
          margin-bottom: px2rem(24);
          cursor: pointer;
          img {
            width: 100%;
          }
        }
      }
      &-rightContent {
        width: calc(78% - 23px);
        height: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        left: 24%;
      }
    }
  }
}
.dialogue {
  width: 11%;
  position: absolute;
  bottom: 2%;
  right: 5%;
  cursor: pointer;
}
.dialogue img {
  width: 100%;
}
.mobileNavBox {
  width: 100%;
  height: px2rem(240);
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
@media screen and (max-width: 768px) {
  .homePage {
    &-TableBox {
      width: 94%;
      height: 80%;
      background-color: #000;
      min-width: auto;
      border-radius: 10px;
      position: fixed;
      top: calc(50% + 30px);
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 12px;
      box-sizing: border-box;
      overflow: hidden;
      &-table {
        width: 100%;
        height: 100%;
        position: relative;
        &-rightContent {
          width: 100%;
          height: 100%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.nav-item {
  position: relative;
  font-family: DINCond-MediumAlternate;
  color: #fff;
  &-textbox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-left: px2rem(16);
    display: flex;
    align-items: center;
  }
  &-text {
    white-space: nowrap;
    font-size: px2rem(28);
  }
  &-icon {
    width: px2rem(27);
    height: px2rem(27);
    margin-right: px2rem(8);
    // background: red;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
</style>
