//import Web3 from "web3";

import store from '@/store'
import $ from 'jquery'
import Web3 from 'web3'
import { config } from './config'
//console.log(config, 'utils config', post)
// const abi = require('./abi/abi');

const indexjs = {}

indexjs.tips = {
  titleValSuccess: 'correct',
  titleValError: 'Error',
  titleValSorry: 'Sorry!',

  btnText: 'OK',
  tips1001:
    'The session is incorrect or expired, prompt the user to log in again',
  tips1002: 'Please select the item to buy',
  tips1003: 'The product does not exist',
  tips1004: 'Sold out',
  tips1005: 'Insufficient points, redemption failed',
  tips1006: 'The network is abnormal, please try again later',
  tips1007: 'Please connect DISCORD',
  tips1008: 'Please enter the correct little fox address',
  tips1011: 'The current DISCORD account is bound',
  tips1012: 'Insufficient points, send failed',
  NoOpenQ: 'Without the GEN-MYSTERY BOX, it cannot be opened',
  OpenQ: 'Eligible to open',
  ended: 'Has been opened',
  restTip: 'Successfully reset',
  updateInputNull: 'Please enter the wallet address to switch',
  authDiscord: 'Please authorize discord first',
  updateInputSame: 'The address is the same, no need to change',
  updateSuccess: 'Whitelist Address set to ',
  InsufficientBlindBox: 'Insufficient quantity of MYSTERY BOX',
  tipschainId: 'Please change to Ethereum Chain.',
  signatureTips: 'Signature failed, please refresh the page and try again',
  titleValBindSuccess: 'Good!',
  BindSucceeded: 'Discord connected: ',
  UnboundSucceeded: 'Discord disconnected',
  notMetamask: 'Please install metamask and refresh your browser',
  receiverAddressError: 'Please fill in the correct receiving address',
  transferAmountError: 'Please fill in the correct quantity',
  notEnoughAmount: 'Insufficient quantity'
}

indexjs.dialogueUrl = 'https://discord.gg/PG7tjKwt2n'

indexjs.connectContract = async function (callback) {
  var web3Provider
  let func = async () => {
    if (this.isConnect) {
      indexjs.connectContract()
    }
  }
  if (window.ethereum) {
    window.ethereum.autoRefreshOnNetworkChange = false
    window.ethereum.on('accountsChanged', func)
    window.ethereum.on('chainChanged', (chainId1) => {
      this.chainId = chainId1
      func([this.account])
    })
    web3Provider = window.ethereum
    try {
      await window.ethereum.enable()
    } catch (error) {
      console.error('User denied account access')
    }
  } else if (window.Web3) {
    web3Provider = window.Web3.currentProvider
  } else {
    web3Provider = new Web3.providers.HttpProvider(config.RPC_URL)
  }
  callback(web3Provider)
}
const mainurl = config.mainUrl
var isSend = false
var nowUrl = ''

indexjs.login = function (url, data, success, errFn) {
  if (isSend) return
  isSend = true

  data = JSON.stringify(data)
  $.ajax({
    url: mainurl + url,
    type: 'post',
    data: data,
    headers: {},
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,

    contentType: 'application/json',
    success: function (data) {
      isSend = false
      if (data.code == 0) {
        success(data)
        //setTips(data.code)
      }
    },
    error: function (err) {
      isSend = false
      errFn && errFn(err)
    }
  })
}
indexjs.post = function (url, data, success, errFn) {
  if (url == nowUrl && isSend) return
  nowUrl = url
  isSend = true
  // var str = "";
  data['sessionId'] = sessionStorage.getItem('mySession')
  data = JSON.stringify(data)
  $.ajax({
    url: mainurl + url,
    type: 'post',
    data: data,
    headers: {},
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,

    contentType: 'application/json',
    success: function (data) {
      isSend = false
      nowUrl = ''
      // success(data)
      const code = data.code
      if (code === 0) {
        success && success(data)
      } else {
        switch (code) {
          case 1000:
            indexjs.setTips(code, [
              { key: 'tipSubtitle', value: indexjs.tips.signatureTips }
            ])
            break
          case 1001:
            indexjs.setTips(code, [{ key: 'isRestart', value: true }])
            break
          case 1004:
          case 1005:
          case 1011:
            indexjs.setTips(code, [
              { key: 'tipTitle', value: indexjs.tips.titleValSorry }
            ])
            break
          case 1007:
            indexjs.setTips(code, [{ key: 'tipFun', value: 'goBindDiscord' }])
            break
          default:
            indexjs.setTips(code)
            break
        }
        errFn && errFn(data)
      }
    },
    error: function (err) {
      isSend = false
      errFn && errFn(err)
    }
  })
}

indexjs.setTips = (code, options = []) => {
  store.commit('SET_STATE_MORE', [
    ...[
      { key: 'tipShow', value: true },
      { key: 'tipFun', value: '' },
      { key: 'tipTitle', value: indexjs.tips.titleValError },
      { key: 'tipSubtitle', value: indexjs.tips['tips' + code] },
      { key: 'btnText', value: indexjs.tips.btnText }
    ],
    ...(options || [])
  ])
}

indexjs.formatList2Obj = function (list, keyItem) {
  const obj = {}
  list.forEach((item) => {
    obj[item[keyItem]] = item
  })
  return obj
}

indexjs.getCashed = function (endStr) {
  // const startTime = new Date(startStr.replace(/-/g, '/')).getTime();
  const endTime = new Date(endStr.replace(/-/g, '/')).getTime()
  const noeTime = getNowUTC()

  return noeTime <= endTime ? 0 : 1
}

indexjs.getWeek = function () {
  let year,
    month,
    day,
    time = '12:00:00'
  let week = new Date(getNowUTC()).getDay() //d=0~6(0代表星期天)。
  var date = new Date(getNowUTC())
  const startStr = `${[date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map(tow)
    .join('/')} ${time}` // 2022-01-01 12:0000

  // var startTime = new Date(startStr.replace(/-/g, '/')).getTime();
  let startTime = new Date(startStr).getTime()

  var noeTime = getNowUTC()
  var isStart = noeTime < startTime

  if (week == 0) {
    //7
    date.setDate(date.getDate() + (5 - date.getDay()))
  } else if (week > 5) {
    //6
    date.setDate(date.getDate() + (12 - date.getDay()))
  } else if (week == 5 && !isStart) {
    //5
    date.setDate(date.getDate() + (12 - date.getDay()))
  } else {
    // 1 2 3 4
    date.setDate(date.getDate() + (5 - week))
  }

  year = date.getFullYear()
  month = date.getMonth() + 1
  day = date.getDate()
  let str = `${[year, month, day].map(tow).join('-')} ${time}` //[year, month, day].map(tow).join('-') + ' 12:00:00'
  // let str = tow(year) + '-' + tow(month) + '-' + tow(day) + ' ' + '12' + ':' + '00' + ':' + '00'
  //  startTime = new Date(str).getTime()

  startTime = new Date(str.replace(/-/g, '/')).getTime()

  return startTime
}

function getNowUTC() {
  return new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
    new Date().getUTCHours(),
    new Date().getUTCMinutes(),
    new Date().getUTCSeconds()
  ).getTime()
}
indexjs.getDate = function (startTime) {
  var nowTime = getNowUTC()
  var second = Math.floor((startTime - nowTime) / 1000)
  var day = Math.floor(second / 86400)
  second = second % 86400
  var hour = Math.floor(second / 3600)
  second %= 3600
  var minute = Math.floor(second / 60)
  second %= 60

  let timeObj = {
    day: tow(day),
    hour: tow(hour),
    minute: tow(minute),
    second: tow(second)
  }
  return timeObj
}

function tow(n) {
  return String(n).padStart(2, '0')
  // return n >= 0 && n < 10 ? "0" + n : "" + n;
}

indexjs.contrastTime = function (startTime) {
  var isStart = false
  var noeTime = getNowUTC()
  if (noeTime < startTime) {
    isStart = true
  }
  return isStart
}

indexjs.formatAccount = function (account) {
  if (!account) return ''
  const len = account.length
  return account.substring(0, 5) + '...' + account.substring(len, len - 4)
}

export default indexjs
