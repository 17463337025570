// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'
import Web3 from 'web3'
import store from './store/index.js'
import 'jquery'
import Utils from './common/utils.js'
// import { Core } from '@walletconnect/core'
// import { Web3Wallet } from '@walletconnect/web3wallet'

// const core = new Core({ projectId })

// const web3wallet = await Web3Wallet.init({
//   core,
//   metadata: {
//     name: 'Torizero',
//     description: '',
//     url: 'http://192.168.3.40:8080',
//     icons: []
//   }
// })


// store.commit({type: 'web3Wallet', data: web3wallet})

// store.commit({type: 'web3Modal', data: })

Vue.use(Vuex)
Vue.prototype.Web3 = Web3
Vue.prototype.$http = axios
Vue.prototype.utils = Utils

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
