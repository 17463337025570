<template>
  <div class="login-Container">
    <img src="../assets/images/login/bg.png" alt class="login-Container-Bg" />
    <img
      src="../assets/images/login/mobile_bg.png"
      alt
      class="login-Container-BgMobile"
    />
    <div class="login-Container-Content">
      <div class="login-Container-Content-tipBox">
        <img src="../assets/images/login/tipBg.png" alt />
        <div class="login-Container-Content-tipBox-text">WELCOME</div>
      </div>
      <div class="login-Container-Content-title">
        <img src="../assets/images/login/title.png" alt />
      </div>
      <div class="login-Container-Content-marketBox">
        <img src="../assets/images/login/market.png" alt />
      </div>
      <div class="login-Container-Content-info">
        Here is the place where holders can claim weekly Mystery Boxes, view
        inventory and purchase items.
      </div>
      <div class="login-Container-Content-address">
        <img src="../assets/images/login/inputBg.png" alt />
        <div class="login-Container-Content-address-text">
          {{ account ? account : '' }}
        </div>
      </div>
      <div
        class="login-Container-Content-btn btnScale"
        @click="$emit('connectWallet')"
        v-if="!account"
      >
        <img src="../assets/images/login/btnBg.png" alt />
        <div class="login-Container-Content-btn-text">
          CONNECT WALLET
          <!-- <w3m-button label="CONNECT WALLET" balance="hide" /> -->
        </div>
      </div>
      <div
        class="login-Container-Content-btn btnScale"
        @click="$emit('loginFun')"
        v-if="account"
      >
        <img src="../assets/images/login/btnBg.png" alt />
        <div class="login-Container-Content-btn-text">Enter</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginPage',
  data() {
    return {
      modal: null,
      isEnter: true
    }
  },
  created() {
    if (this.$route.query.Enter) {
      this.isEnter = false
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.account && this.isEnter) {
        this.$emit('loginFun')
      }
    }, 500)
  },
  computed: {
    ...mapGetters(['account', 'isConnect'])
  }
}
</script>

<style lang="scss" scoped>
$login-Container-width: 900px;

.login {
  &-Container {
    width: 45%;
    height: auto;
    transition: 0.5s;
    @extend .fixed-center;
    &-Bg {
      width: 100%;
      display: block;
    }
    &-BgMobile {
      display: none;
    }
    &-Content {
      width: 72%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &-tipBox {
        width: 88%;
        margin: 0 auto;
        position: relative;
        img {
          width: 100%;
        }
        &-text {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(30);
          font-family: DINCond-MediumAlternate;
        }
      }
      &-title {
        width: 60%;
        margin: 0 auto;
        margin-top: px2rem(20);
        margin-bottom: px2rem(15);
        img {
          width: 100%;
        }
      }
      &-marketBox {
        width: 40%;
        margin: 0 auto;
        margin-bottom: px2rem(20);
        img {
          width: 100%;
        }
      }
      &-info {
        width: 84%;
        margin: 0 auto;
        font-size: px2rem(18);
        color: #fff;
        line-height: px2rem(28);
        font-family: Regular;
      }
      &-address {
        width: 84%;
        margin: 0 auto;
        position: relative;
        margin-top: px2rem(16);
        @extend .pointer;
        img {
          width: 100%;
        }
        &-text {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(22);
          font-family: Light;
        }
      }
      &-btn {
        width: 84%;
        margin: 0 auto;
        position: relative;
        margin-top: px2rem(16);
        @extend .pointer;
        transition: 0.5s;

        img {
          width: 100%;
        }
        &-text {
          @extend .pos-flex-center;
          color: #fff;
          font-size: px2rem(22);
          font-family: Light;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .login {
    &-Container {
      width: 75%;
      &-Content {
        &-marketBox {
          margin-top: px2rems(12);
        }
        &-tipBox {
          margin-bottom: px2rems(24);
          &-text {
            font-size: px2rems(30);
          }
        }
        &-info {
          font-size: px2rems(14);
          line-height: px2rems(28);
          margin-top: px2rems(24);
        }
        &-address {
          width: 80%;
          margin-top: px2rems(12);
          &-text {
            font-size: px2rems(14);
          }
        }
        &-btn {
          width: 80%;
          margin-top: px2rems(6);
          &-text {
            font-size: px2rems(14);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    &-Container {
      width: 80%;
      &-Bg {
        display: none;
      }
      &-BgMobile {
        width: 100%;
        display: block;
      }
      &-Content {
        width: 94%;
        &-tipBox {
          &-text {
            font-size: px2rems(36);
          }
        }
        &-title {
          width: 70%;
        }
        &-info {
          width: 94%;
          font-size: px2rems(24);
          line-height: px2rems(28);
        }
        &-address {
          width: 94%;
          &-text {
            font-size: px2rems(22);
          }
        }
        &-btn {
          width: 94%;
          &-text {
            font-size: px2rems(22);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .login {
    &-Container {
      width: 88%;
    }
  }
}
</style>
