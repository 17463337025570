import { whitePaperURL } from './config'
import { NavNo } from './enum'

export const baseImg = {
  bgImg: require('../assets/images/normal.png'),
  bgActiveImg: require('../assets/images/active.png'),
  mobileBgImg: require('../assets/images/normalMobile.png'),
  mobileBgActiveImg: require('../assets/images/activeMobile.png')
}

export const navDataList = [
  { title: 'INVENTORY', action: NavNo.Inventory, icon: 'in', ...baseImg },
  { title: 'MYSTERY BOX', action: NavNo.MysteryBox, icon: 'myBox', ...baseImg },
  {
    title: 'MARKETPLACE',
    action: NavNo.MarketPlace,
    icon: 'market',
    ...baseImg
  },
  {
    title: 'ACCOUNT LINK',
    action: NavNo.AccountLink,
    icon: 'acLink',
    ...baseImg
  },
  { title: 'FRAGMENTS', action: NavNo.Fragments, icon: 'fragment', ...baseImg },
  {
    title: 'WHITE PAPER',
    action: NavNo.WhitePaper,
    icon: '',
    ...baseImg,
    external: whitePaperURL
  }
]
