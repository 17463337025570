<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',

  created() {
    this.$store.dispatch('getGlobalConfig')
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

@font-face {
  font-family: DINCond-BoldAlternate;
  src: url('./assets/fonts/DINCond-BoldAlternate.otf') format('truetype');
}
@font-face {
  font-family: DINCond-MediumAlternate;
  src: url('./assets/fonts/DINCond-MediumAlternate.otf') format('truetype');
}
@font-face {
  font-family: Bold;
  src: url('./assets/fonts/Bold.ttf') format('truetype');
}
@font-face {
  font-family: Heavy;
  src: url('./assets/fonts/Heavy.ttf') format('truetype');
}
@font-face {
  font-family: Light;
  src: url('./assets/fonts/Light.ttf') format('truetype');
}
@font-face {
  font-family: Medium;
  src: url('./assets/fonts/Medium.ttf') format('truetype');
}
@font-face {
  font-family: Regular;
  src: url('./assets/fonts/Regular.ttf') format('truetype');
}
img {
  display: block;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
  html,
  body,
  #app {
    /*height: auto !important;*/
  }
}
</style>
