<template>
  <div class="accountLink-Box">
    <div class="accountLink-Header">
      <div class="blindBoxTitle">
        <div class="title_text">
          <div>[</div>
          Fragments Transfer
          <div>]</div>
        </div>
      </div>
      <div class="blindBoxDesc">Transfer your Fragments</div>
      <fragment-num></fragment-num>
    </div>
    <img src="../assets/images/shop/shopImg/line.png" class="lineImg" alt />
    <div class="accountLink-content">
      <div class="accountLink-titleBox">
        <div class="accountLink-titleText">Receiver Address</div>
      </div>
      <div class="accountLink-imageBtn">
        <div class="accountLink-imageBtn-discordInput">
          <img src="../assets/images/shop/accountLink/concatActive.png" alt />
          <input
            type="text"
            v-model="receiver"
            placeholder="Please fill in the recipient"
          />
        </div>
      </div>

      <div class="accountLink-titleBox">
        <div class="accountLink-titleText">Transfer Quantity</div>
      </div>
      <div class="accountLink-imageBtn">
        <div class="accountLink-imageBtn-discordInput">
          <img src="../assets/images/shop/accountLink/concatActive.png" alt />
          <input
            type="text"
            v-model.number="amount"
            placeholder="Please fill in the quantity"
          />
        </div>
      </div>
      <div class="accountLink-imageBtn btnScale" @click="confirmTransfer()">
        <div class="btn-box">
          <img src="../assets/images/shop/accountLink/btn.png" alt />
          <div class="btn-text">Transfer</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FragmentNum from './FragmentNum.vue'
export default {
  name: 'FragmentView',
  components: {
    FragmentNum
  },
  data() {
    return {
      username: '',
      discordPortrait: null,
      receiver: '',
      amount: '',
      bindtype: 1,
      nonce: 'Sign this message to login account link.',
      isClick: false
    }
  },
  computed: {
    ...mapGetters(['web3', 'account', 'isConnect', 'integralVal'])
  },
  watch: {
    account() {
      // this.account = this.$store.state.account;
      this.init()
    }
    // "$store.state.isConnect": function() {
    //   this.isConnect = this.$store.state.isConnect;
    // }
  },
  created() {
    // this.account = this.$store.state.account;
    // this.isConnect = this.$store.state.isConnect;
    this.init()
  },
  methods: {
    ...mapMutations(['SET_STATE', 'SET_STATE_MORE']),
    confirmTransfer() {
      if (!this.receiver || !/^0[xX][0-9a-fA-F]{40}$/.test(this.receiver)) {
        console.log('address error')
        this.utils.setTips('', [
          { key: 'tipTitle', value: this.utils.tips.titleValSorry },
          { key: 'tipSubtitle', value: this.utils.tips.transferAmountError }
        ])
        return
      }

      if (!/\d+/.test(this.amount) || this.amount === 0) {
        this.utils.setTips('', [
          { key: 'tipTitle', value: this.utils.tips.titleValSorry },
          { key: 'tipSubtitle', value: this.utils.tips.transferAmountError }
        ])

        return
      } else if (this.amount > this.integralVal) {
        this.utils.setTips('', [
          { key: 'tipTitle', value: this.utils.tips.titleValSorry },
          { key: 'tipSubtitle', value: this.utils.tips.notEnoughAmount }
        ])
        return
      }

      this.utils.post(
        'sendIntegral',
        {
          address: this.account,
          receiver: this.receiver,
          amount: this.amount
        },
        () => {
          this.utils.setTips('', [
            { key: 'tipTitle', value: 'Fragments Transter' },
            { key: 'tipSubtitle', value: 'Transfer Success' }
          ])
          this.receiver = ''
          this.amount = ''
          this.$store.dispatch('getIntegral')
        }
      )
    },
    init() {
      // this.getUserInfo()
      if (this.$route.query.code) {
        this.getCode(this.$route.query.code)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lineImg {
  width: 100%;
}
.accountLink {
  &-Box {
    width: 100%;
    height: 100%;
    padding: 2% 3%;
    box-sizing: border-box;
  }
  &-Header {
    width: 100%;
    margin-bottom: 2%;
    position: relative;
  }
  &-content {
    width: 100%;
    height: calc(100% - 90px);
    position: relative;
  }
  &-titleText {
    line-height: px2rem(30);
    font-size: px2rem(16);
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: px2rem(24);
    margin-bottom: px2rem(12);

    font-family: Bold;
    img {
      width: px2rem(20);
      margin-left: px2rem(20);
      cursor: pointer;
    }
    span {
      margin-left: 12px;
    }
  }
  &-titleBox {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  &-imageBtn {
    width: 60%;
    cursor: pointer;
    margin-bottom: 1%;
    img {
      width: 100%;
    }
    &-discordInput {
      width: 100%;
      height: auto;
      position: relative;
      // margin-bottom: px2rem(20);
      img {
        width: 100%;
      }
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        outline: none;
        color: #fff;
        font-size: px2rem(16);
        padding: 0 4%;
        box-sizing: border-box;
      }
    }
  }
}

.btn-box {
  position: relative;
  margin-top: px2rem(30);
}
.btn-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: px2rem(30);
}

@media screen and (max-width: 768px) {
  .accountLink {
    &-Box {
      padding: 0;
    }
    &-content {
      padding-top: 6%;
      box-sizing: border-box;
    }
    &-titleText {
      width: 90%;
      margin: 0 auto;
      line-height: px2rems(30);
      font-size: px2rems(20);
      margin-top: px2rems(24);
      margin-bottom: px2rems(12);
      font-family: Bold;
      img {
        width: px2rems(20);
        margin-left: px2rems(20);
      }
    }
    &-titleBox {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-bottom: px2rems(6);
      div {
        justify-content: space-between;
      }
    }
    &-imageBtn {
      width: 90%;
      cursor: pointer;
      margin: 0 auto;
      img {
        width: 100%;
      }
      &-discordInput {
        width: 100%;
        height: auto;
        position: relative;
        margin-bottom: px2rems(20);
        img {
          width: 100%;
        }
        input {
          font-size: px2rems(20);
        }
      }
    }
  }

  .btn-box {
    position: relative;
    margin-top: px2rems(30);
  }
  .btn-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: px2rems(30);
  }
}
</style>
